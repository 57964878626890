.enemy {
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    opacity: 1;
    animation: wiggle-animation 2050ms forwards infinite alternate-reverse;
    img {
        width: 70px;
        height: 70px;
        transform: rotate(180deg);
    }
    p {
        width: 400%;
        font-size: 16px;
    }
}

.enemy-shield {
    position: absolute;
    opacity: 0.7;
    width: 80;
    height: 80;

    &--destroyed {
        animation: shield-despawn 150ms forwards;
    }
}

@keyframes shield-despawn {
    0% {
        opacity: 0.7;
        transform: scale(1);
    }

    50% {
        opacity: 0.5;
        transform: scale(1);
    }
    66% {
        opacity: 0.5;
        transform: scale(2);
    }
    100% {
        opacity: 0;
        transform: scale(4);
    }
}

.enemy-destruction {
    width: 70px; /* taille de ton sprite */
    height: 70px;
    position: absolute;
    background-size: cover;
    opacity: 0;
    animation: sprite-animation 600ms forwards ease-in-out;
}

@keyframes sprite-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    16% {
        opacity: 1;
        transform: scale(2);
    }
    32% {
        opacity: 1;
        transform: scale(1.5);
    }
    50% {
        opacity: 0.7;
        transform: scale(0.7);
    }
    66% {
        opacity: 0.5;
        transform: scale(0.5);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes spawn-animation {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }
    16% {
        opacity: 0.5;
        transform: scale(0.5);
    }
    32% {
        opacity: 0.5;
        transform: scale(0.7);
    }
    50% {
        opacity: 0.7;
        transform: scale(1);
    }
    66% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes wiggle-animation {
    0% {
        transform: translateX(10px);
    }
    16% {
        transform: translateX(-10px);
    }
    32% {
        transform: translateX(5px);
    }
    50% {
        transform: translateX(-10px);
    }
    66% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(-5px);
    }
}
