#loading_circle {
    justify-self: center;
    align-self: center;
    width: 50px;
    height: 50px;
    opacity: 0.7;
    animation: rotateLoading linear forwards infinite 1500ms;
}

@keyframes rotateLoading {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}
