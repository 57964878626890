@import '../../assets/mixins.scss';

.aside {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 35%;
    min-height: 395px;
    gap: 15px;
    padding: 50px 77px 50px 80px;
    border: 2px solid #73cdff;
    &__title {
        font-size: 20px;
        font-weight: 400;
    }
    &__list {
        display: flex;
        flex-direction: column;
        text-decoration: underline;
        cursor: pointer;
        gap: 15px;
        font-size: 14px;
        line-height: 1.2;
    }
    .link-btn {
        align-self: flex-end;
    }
}

@include tabletQ {
    .aside {
        width: 60%;
    }
}

@include mobileQ {
    .aside {
        width: 60%;
        padding: 25px;
        min-height: 250px;
    }
}
