@import './assets/mixins.scss', './assets/keyframes.scss';

//CSS Output
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
main,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
// End of CSS Reset

// Imports
@import './assets/fonts/font-faces.scss';

* {
    box-sizing: border-box;
}

body {
    background: $primary-bg-color;
    font-family: Comfortaa;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all ease-in-out 300ms;
}

.light {
    background: none;
    font-family: Comfortaa;
    color: black;
    animation-name: fadeIn 300ms both;
    header {
        background-color: $primary-bg-color;
    }
}

a {
    color: white;
}

footer {
    width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    height: 100vh;
}

.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

h1,
h2 {
    display: flex;
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 23px;
}

h3,
h4,
h5,
h6 {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 23px;
}

a {
    display: flex;
    height: auto;
    padding: 10px;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 200px;
    padding-right: 200px;
    transition: all ease-in-out 300ms;
}

.link-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #1e1e1e;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    width: fit-content;
    height: 28px;
    border-radius: 6px;
    background: linear-gradient(0deg, #73cdff 0%, #73cdff 100%);
}

@include tabletQ {
    body {
        background-size: cover;
        background-position: top center;
    }
    .page {
        padding-top: 40px;
        padding-left: 100px;
        padding-right: 100px;
    }

    .light {
        header {
            position: fixed;
            top: 0;
            z-index: 8;
            width: 100%;
            background: none;
            color: black;
        }
    }
    .main-container {
        margin-top: 70px;
    }
    .page {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 25px;
        padding-right: 25px;
    }
}
