@import './variables';

@mixin mobileQ {
    @media (max-width: $mobile-width) {
        @content;
    }
}

@mixin tabletQ {
    @media (max-width: $tablet-width) {
        @content;
    }
}
