@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes gradientMove {
    0% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 88% 0%;
    }
}
