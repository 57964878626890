@import '../../assets/variables', '../../assets/mixins.scss';

.logo-band {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 180px;
}

.rainbow-dash {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    padding: 2px;
    height: 10px;
    overflow: hidden;
}

.colorband {
    display: flex;
    position: relative;
    width: 100%;
    top: -300px;
    border-radius: 100%;
    padding: 100%;
    background: $main-gradient-looped;
    background-size: 400% 100%;
    animation: gradientMove 6s linear infinite both;
}

@include mobileQ {
    .logo-band {
        width: 100%;
        height: 100px;
    }
}
