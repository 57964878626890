@import '../../assets/variables';

.audio-player {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 10px;
    margin: 20px 0;
    border: 2px solid $primary-border-color;
    background: $primary-bg-color;
    color: white;
    &__selection {
        width: 100%;
        padding: 10px 10px;
        font-size: 16px;
        &__song {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            margin: 10px 0;
            border: 2px solid $primary-border-color;
            opacity: 1;
            transition: all ease-in-out 200ms;
            transform: none;
            p {
                transition: all ease-in-out 200ms;
            }
            &:hover {
                transform: scale(1.03);
            }
        }
    }
    &__current-play {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background: $primary-bg-color;
        padding: 30px 10px;
        border-radius: 5px;
        .playbtn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary-font-color;
            background: $main-gradient;
            border: none;
            width: auto;
            padding: 10px;
            border-radius: 50%;
            margin-bottom: 30px;
        }
        .playbtn-i {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 70px;
            font-size: 50px;
            padding: 10px;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
    }
    &__bar {
        width: 70%;
    }
    &__duration {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 70%;
        padding: 5px 0;
    }
    &__volume-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 20px;
        top: 128px;
    }
    &__volume {
        display: flex;
        background: $main-gradient;
        width: 90px;
        padding: 0;
        transform: rotate(-90deg);
        transition: opacity ease-in-out 400ms;
        opacity: 1;
        &--collapsed {
            display: flex;
            background: $main-gradient;
            width: 90px;
            transform: rotate(-90deg);
            transition: opacity ease-in-out 400ms;
            opacity: 0;
        }
        &--icon {
            cursor: pointer;
            position: absolute;
            bottom: -38px;
            display: flex;
            font-size: 16px;
        }
    }
}

.volume-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 5px;
    color: white;
    background: none;
    border: none;
    border-radius: 50%;
}
