@import '../../assets/variables.scss', '../../assets/mixins.scss',
    '../../assets/keyframes.scss';

.carousel-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 380px;
    margin-bottom: 100px;
}

.carousel {
    width: 100%;
    height: 100%;
}

.slide {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: all ease-in-out 1000ms;
    &--display {
        opacity: 1;
        left: 0;
    }
    &--right {
        opacity: 0;
        left: -100%;
    }
    &--left {
        opacity: 0;
        left: 100%;
    }
    &--hide {
        opacity: 0;
        left: 100%;
    }
}

@keyframes slideIn {
    to {
        right: 0;
        opacity: 1;
    }
}

.catch {
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    padding: 20px;
    font-family: Comfortaa;
    font-size: 35px;
    line-height: 40px;
    text-transform: uppercase;
    background: $main-gradient-looped;
    background-size: 400% 100%;
    animation: gradientMove 6s linear infinite both;
}

@include mobileQ {
    .carousel-wrapper {
        height: 250px;
        margin-bottom: 0px;
    }
    .carousel__pagination {
        height: 5px;
        input[type='radio'] {
            width: 8px;
        }
    }
    .slide {
        object-fit: contain;
        object-position: 50% 25%;
    }
    .catch {
        text-align: center;
        font-size: 20px;
    }
}
