@import '../../assets/variables.scss', '../../assets/mixins.scss';

.card {
    &--dark {
        z-index: 0;
        background: $primary-bg-color;
        color: white;
    }
    &--light {
        z-index: 0;
        background: white;
        color: black;
    }
}
.card3d {
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/1;
    width: 254px;
    height: auto;
    padding: 58px;
    gap: 16px;
    border: $primary-border-color 2px solid;

    transform-style: preserve-3d;
    transition: all 0.3s ease-in-out;
    h3 {
        font-size: 20px;
        font-weight: 700;
    }
    p {
        font-size: 14px;
        line-height: 24px;
        flex: 1;
    }
    &:before,
    &:after {
        position: absolute;
        content: '';
        border: $primary-border-color 2px solid;
        transition: all 0.3s ease-in-out;
    }
    &:before {
        width: 100%;
        top: -13px;
        left: -1px;
        height: 8px;
        transform: skewX(45deg) rotatex(90deg);
        transform-origin: center bottom;
    }

    &:after {
        width: 9px;
        left: -12px;
        top: -1px;
        height: 100%;
        transform: skewY(45deg) rotateY(90deg);
        transform-origin: center right;
    }
    &:hover {
        transform: translateX(10px) translateY(10px);
    }
    &:hover:before {
        transform: skewX(45deg) rotatex(0deg);
    }
    &:hover:after {
        transform: skewY(45deg) rotateY(0deg);
    }
    &--light {
        position: relative;
        display: flex;
        flex-direction: column;
        aspect-ratio: 1/1;
        width: 254px;
        height: auto;
        background: white;
        color: black;
        padding: 58px;
        gap: 16px;
        border: $primary-border-color 2px solid;
        transform: translatez(50px);
        transform-style: preserve-3d;
        transition: all 0.3s ease-in-out;
        h3 {
            font-size: 20px;
            font-weight: 700;
        }
        p {
            font-size: 14px;
            line-height: 24px;
            flex: 1;
        }
        &:before,
        &:after {
            position: absolute;
            content: '';
            border: $primary-border-color 2px solid;
            transition: all 0.3s ease-in-out;
        }
        &:before {
            width: 100%;
            top: -13px;
            left: -1px;
            height: 8px;
            transform: skewX(45deg) rotatex(90deg);
            transform-origin: center bottom;
        }

        &:after {
            width: 9px;
            left: -12px;
            top: -1px;
            height: 100%;
            transform: skewY(45deg) rotateY(90deg);
            transform-origin: center right;
        }
        &:hover {
            transform: translateX(10px) translateY(10px);
        }
        &:hover:before {
            transform: skewX(45deg) rotatex(0deg);
        }
        &:hover:after {
            transform: skewY(45deg) rotateY(0deg);
        }
    }
}

@include tabletQ {
    .card3d {
        width: 100%;
        max-width: 500px;
    }
}

@include mobileQ {
    .card3d {
        width: 100%;
        max-width: 400px;
    }
}
