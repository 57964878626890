@import '../../assets/variables.scss', '../../assets/mixins.scss';

.article {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    padding-top: 50px;
    &__title {
        display: flex;
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 23px;
    }
    &__content {
        display: flex;
        flex-direction: row;
        line-height: 24px;
        width: 100%;
        &--v {
            width: 100%;
            p {
                margin-left: 23px;
                font-size: 14px;
                &:not(:last-child) {
                    margin-bottom: 14px;
                }
            }
            a {
                margin-left: 23px;
                font-size: 14px;
            }
        }
    }
    &__image-container {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-self: center;
        align-items: center;
        box-sizing: border-box;
        height: 350px;
        width: 80%;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 20px;
        padding: 20px 0;
        border: 2px solid $primary-border-color;
        background: $primary-bg-color-light;
        &::before {
            content: '';
            z-index: 1;
            display: block;
            width: 50px;
            height: 100%;
            background-color: $primary-bg-color;
            border: 2px solid $primary-border-color;
            position: absolute;
            left: -5px;
            transform: skew(0, 50deg) translateY(30px);
        }
        &::after {
            content: '';
            z-index: 1;
            display: block;
            width: 50px;
            height: 100%;
            background-color: $primary-bg-color;
            border: 2px solid $primary-border-color;
            position: absolute;
            left: calc(100% - 50px);
            transform: skew(0, -50deg) translateY(33px);
        }
        video {
            display: flex;
            justify-self: center;
            align-self: center;
            max-width: 100%;
            height: auto;
            background-color: $primary-bg-color-light;
        }
        .carousel-button {
            position: absolute;
            transform: translateY(-50%);
            transition: all ease-in-out 300ms;
            opacity: 0.7;
            background-color: $primary-bg-color-dark;
            color: $primary-border-color;
            border: 1px solid $primary-border-color;
            padding: 10px;
            cursor: pointer;
            z-index: 4;
            &:hover {
                opacity: 1;
            }
        }

        .carousel-button.left {
            left: 10px;
            transform: scale(2);
        }

        .carousel-button.right {
            right: 10px;
            transform: scale(2);
        }
    }
    &__image {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        margin: 0;
        max-height: 90%;
        max-width: 500px;
        object-fit: fill;
        transition: all 0.3s ease-in;
        opacity: 0;

        &-center {
            opacity: 1;
            z-index: 2;
        }
        &-previous {
            z-index: 0;
            left: 0;
            transform: translateX(-50%) translateY(-20%) scale(0.4);
            opacity: 1;
        }
        &-next {
            z-index: 0;
            left: 100%;
            transform: translateX(-50%) translateY(-20%) scale(0.4);
            opacity: 1;
        }
        &-unselected {
            top: 400%;
            opacity: 0;
        }
        &-modal {
            z-index: 6;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            &-bg {
                z-index: 6;
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: black;
                opacity: 0.4;
            }
            &-content {
                display: flex;
                position: absolute;
                width: 80%;
                max-height: 80%;
                background: $primary-bg-color;
                border-radius: 25px;
                margin: auto;
                z-index: 7;
            }
            .article__image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                margin: 0;
                max-height: 90%;
                max-width: 85%;
                object-fit: fill;
                transition: all 0.5s ease-in-out;
                opacity: 0;
                &-center {
                    opacity: 1;
                    z-index: 8;
                }
                &-previous {
                    left: 0;
                    transform: translateX(-50%) translateY(-20%) scale(0.3);
                    opacity: 0.7;
                }
                &-next {
                    left: 100%;
                    transform: translateX(-50%) translateY(-20%) scale(0.3);
                    opacity: 0.7;
                }
                &-unselected {
                    top: 300%;
                    opacity: 0;
                }
                &:hover {
                    cursor: default;
                }
            }
            .article__image-container {
                display: flex;
                position: relative;
                flex-direction: row;
                justify-self: center;
                align-items: center;
                margin-top: 0px;
                min-height: 600px;
                max-height: 800px;
                width: 100%;
                overflow: hidden;
                margin: 0 auto;
            }
            &::before {
                width: 73px;
                z-index: 2;
            }
            &::after {
                width: 73px;
                z-index: 2;
            }
            .carousel-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: rgba(0, 0, 0, 0.5);
                color: $primary-border-color;

                padding: 10px;
                cursor: pointer;
                z-index: 5;
            }
            .carousel-button.left {
                left: 10px;
                transform: scale(2);
            }

            .carousel-button.right {
                right: 10px;
                transform: scale(2);
            }
            .fullscreen-btn {
                display: none;
            }
            .modal-close-btn {
                cursor: pointer;
                position: absolute;
                top: -20px;
                right: -20px;
                z-index: 5;
                color: $primary-bg-color;
                background-color: white;
                border-radius: 50%;
            }
            .fa-circle-xmark {
                width: 40px;
                height: 40px;
                font-size: 40px;
            }
        }
    }
}

.fullscreen-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0px;
    height: 0px;
    z-index: 2;
    position: absolute;
    bottom: -10px;
    right: 17px;
    background: none;
    border: none;
    .fa-expand {
        width: 50px;
        height: 50px;
        color: $primary-border-color;
        transition: all ease-in-out 300ms;
        transform-origin: center;
        transform: scale(1.8);
    }
    &:hover {
        .fa-expand {
            transform-origin: center;
            transform: scale(2.3);
        }
    }
}

.pin {
    display: flex;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0px;
    bottom: 0;
}

.colorband-v {
    display: flex;
    height: inherit;
    width: 5px;
    padding: 0 2px;
    background: $main-gradient-v;
}

@include mobileQ {
    .article {
        &__image-container {
            margin-top: 20px;
            aspect-ratio: 1/1;
            height: auto;
            width: 100%;
            overflow: hidden;
            margin: 0 auto;
            video {
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-70%);
                position: absolute;
                display: flex;
                justify-self: center;
                align-self: center;
                width: 100%;
                height: auto;
                margin: 40px auto;
                border: 1px solid $primary-border-color;
            }
        }
        &__image {
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            position: absolute;
            margin: 0;
            max-height: 100%;
            max-width: 100%;
            &-center {
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                z-index: 2;
            }
            &-previous {
                top: 0;
                left: 0;
                transform: translate(-50%, -50%) scale(0.4);
                opacity: 0;
            }
            &-next {
                top: 0;
                left: 100%;
                transform: translate(-50%, -50%) scale(0.4);
                opacity: 0;
            }
            &-unselected {
                bottom: -400%;
                opacity: 0;
            }
        }
    }
}
