@import '../assets/variables';

.player {
    position: absolute;
    width: 50px;
    height: 50px;
    .player-ship {
        width: inherit;
        height: inherit;
    }
    &--hit {
        animation: hitPlayerAnim 200ms forwards;
    }
}

@keyframes hitPlayerAnim {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.game {
    position: absolute;
    display: flex;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 1440px; /* Limiter la largeur à 1440px */
    margin: 0 auto; /* Centrer le conteneur */
    overflow: hidden; /* Empêcher le débordement */
    button {
        cursor: pointer;
        background-color: $primary-border-color;
        width: fit-content;
        height: 50px;
        padding: 5px;
        font-family: Comfortaa;
        font-size: 24px;
        font-weight: bold;
        border: none;
    }
    a {
        text-decoration: none;
    }
}

.start-screen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 250px;
    align-items: center;
}

.controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;

    &__scheme {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        gap: 50px;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 150px;
        }
        img {
            object-fit: contain;
        }
        .controlsArrow {
            width: 200px;
        }
        .controlsSpaceBar {
            width: 400px;
        }
    }
}

.game-interface {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
    font-size: 16px;
    &-health {
        display: block;
        position: absolute;
        width: 64px;
        height: 16px;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        &--static {
            display: block;
            position: absolute;
            width: 128px;
            height: 32px;
            top: 250px;
            left: 100%;
            transform: translateX(-110%);
        }
    }
    &-score {
        position: absolute;
        font-size: 24px;
        text-transform: uppercase;
        top: 250px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    &-hitscreen {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100vh;
        opacity: 0;
        background: radial-gradient(
            circle at center,
            rgba(255, 0, 0, 0.6) 50%,
            rgba(255, 0, 0, 0.4) 70%,
            rgba(255, 0, 0, 0.1) 90%,
            transparent 100%
        );
        transition: opacity 50ms ease-out;
        &--hit {
            opacity: 0.6;
        }
    }
}

.game-over {
    &-screen {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 100px;
    }
    &-score {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &-catch {
        font-size: 16px;
        margin-bottom: 20px;
    }
}
