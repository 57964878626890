@import '../../assets/variables.scss';

.slider-desktop {
    justify-self: flex-end;
    align-self: center;
    margin-right: 25px;
}

.slider-mobile {
    position: absolute;
    left: calc(50% - 30px);
    justify-self: center;
    align-self: center;
    margin: 10px 0;
}

.toggle-slider {
    display: block;
    position: relative;
    width: 60px;
    height: 34px;
    &--center {
        display: flex;
        position: relative;
        width: 60px;
        height: 34px;
        align-self: center;
        justify-self: center;
    }
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $main-gradient-reverse;
    border-radius: 34px;
    cursor: pointer;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input[type='checkbox'] {
    display: none;
}

input[type='checkbox']:checked + .slider {
    background: $main-gradient;
}

input[type='checkbox']:checked + .slider:before {
    transform: translateX(26px);
}
