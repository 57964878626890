@import '../../assets/mixins.scss', '../../assets/variables.scss';

.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 63px;
    margin: 0 20px;
    gap: 53px;
    &-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 58px;
        padding: 20px 0;
        z-index: 2;
        line-height: 24px;
        text-align: center;
    }
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}

.nav-dropdown {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    &-menu {
        width: 100%;
        li {
            padding: 10px;
            width: 100%;
            border-top: 1px solid $primary-bg-color-dark;
            border-bottom: 1px solid $primary-bg-color-light;
            i {
                position: relative;
                left: 40%;
                font-size: 24px;
                justify-self: flex-end;
                text-align: end;
            }
        }
    }
    &-toggle {
        width: 100%;
    }
}

.active {
    color: $primary-border-color;
}

.nav-btn {
    display: flex;
    text-decoration: none;
    color: white;
    padding: 10px;
}
.play-game-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;
    background-color: $primary-border-color;
    width: 200px;
    height: 50px;
    padding: 5px;
    font-family: Comfortaa;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border: none;
}

.fa-chevron-down {
    margin-left: 30px;
}
