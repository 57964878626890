@import '../../assets/variables.scss';
.open-animation {
    &__wrapper {
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: $primary-bg-color;
        animation: openAnimDisplay 4s forwards;
    }
    &__content {
        position: relative;
        top: auto;
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        animation: spreadWide 4s forwards;
    }
    &__logo {
        display: flex;
        height: 150px;
        opacity: 0;
        animation: slideInFromBottom 3s forwards;
        animation-delay: 0.3s;
    }
    &__colorband {
        display: block;
        width: 80%;
        padding: 2px;
        background: $main-gradient;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes spreadWide {
    0% {
        width: 66px;
        top: -10%;
    }
    50% {
        width: 100%;
        top: 0;
    }
    100% {
        width: 100%;
        top: -40%;
    }
}

@keyframes openAnimDisplay {
    0% {
        display: flex;
        opacity: 1;
        height: 100%;
        width: 100%;
    }
    70% {
        opacity: 1;
    }
    99% {
        height: 100%;
        width: 100%;
    }
    100% {
        opacity: 0;
        height: 0;
        width: 0;
    }
}
