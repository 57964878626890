@import '../../assets/variables.scss', '../../assets/mixins.scss';

.align-right {
    text-align: end;
}
.xp__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
    font-size: 14px;
}
.banner {
    &-dark {
        background: none;
        color: white;
    }
    &-light {
        background: none;
        color: black;
    }
}

@for $i from 1 through 3 {
    .banner3d-#{$i} {
        display: flex;
        width: 100%;
        perspective: 100vh;
        transform-style: preserve-3d;
        margin-bottom: 70px;
    }

    .banner3d-#{$i}-front {
        width: 100%;
        padding: 60px;
        border: $primary-border-color 1px solid;
        z-index: 2;
        transform-style: preserve-3d;
        font-size: 14px;
        line-height: 24px;

        &:before {
            z-index: -1;
            position: absolute;
            content: '';
            overflow: hidden;
            width: 100%;
            left: -2px;
            height: 50px;
            border: $primary-border-color 2px solid;
            top: -55px;
            transform-origin: center bottom;
            transform: rotatex(90deg);
        }

        &:after {
            z-index: -1;
            position: absolute;
            content: '';
            width: 100%;
            left: -2px;
            height: 50px;
            border: $primary-border-color 2px solid;
            bottom: -55px;
            transform-origin: center top;
            transform: rotatex(-90deg);
        }
    }
}

@include mobileQ {
    .xp__content {
        width: 80%;
        margin: auto;
    }
    @for $i from 1 through 3 {
        .banner3d-#{$i}-front {
            padding: 30px;
        }
    }
}
