@import '../assets/mixins.scss';

.interests {
    display: flex;
    &__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4%;
    }
}

@include tabletQ {
    .interests__cards {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }
}

@include mobileQ {
    .interests__cards {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        gap: 25px;
    }
}
