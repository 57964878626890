.projectile {
    position: absolute;
    width: auto;
    height: 20px;
}

.player-proj {
    transform: rotate(-90deg);
}

.enemy-proj {
    transform: rotate(90deg) scale(2);
}
