@import '../../assets/variables.scss', '../../assets/mixins.scss',
    '../../assets/keyframes.scss';

.section {
    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-bottom: 100px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 25px;
    }
    &__title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: none;
        width: 100%;
        font-size: 40px;
        text-transform: uppercase;
        h2 {
            display: flex;
            height: fit-content;
            line-height: 40px;
            position: relative;
            top: 10px;
            background: none;
            z-index: 1;
        }
        &__colorband {
            position: relative;
            top: -35px;
            display: block;
            width: 100%;
            height: 33px;
            padding: 10px 0;
            background: $main-gradient-looped;
            background-size: 400% 100%;
            animation: gradientMove 6000ms linear infinite;
        }
    }
}

@include mobileQ {
    .section {
        &__header {
            margin-bottom: 50px;
        }
    }
}
