@import '../assets/mixins.scss', '../assets/keyframes.scss';

.home {
    padding-bottom: 100px;
    transition: all ease-in-out 300ms;
}
.content-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 115px;
    gap: 50px;
    margin: 0 100px;
}

.articles-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 65%;
}

.slide-in {
    animation: slideInFromLeft 1000ms ease-in-out forwards;
}

.fade-in {
    animation: fadeIn 1000ms ease-in-out forwards;
}

.arrow {
    display: flex;
    position: relative;
    width: 24px;
    height: 24px;
    top: 50px;
    left: -10px;
    cursor: pointer;
    padding: 20px;
}

@include tabletQ {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@include mobileQ {
    .content-container {
        margin: 0 20px;
        padding-bottom: 60px;
        gap: 60px;
    }
    .articles-container {
        width: 100%;
        justify-content: center;
    }
}
