@font-face {
    font-family: 'Comfortaa';
    src: url('Comfortaa/Comfortaa-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Comfortaa';
    src: url('Comfortaa/static/Comfortaa-Light.ttf') format('truetype');
    font-weight: 300; /* Light */
}

@font-face {
    font-family: 'Comfortaa';
    src: url('Comfortaa/static/Comfortaa-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
}

@font-face {
    font-family: 'Comfortaa';
    src: url('Comfortaa/static/Comfortaa-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium */
}

@font-face {
    font-family: 'Comfortaa';
    src: url('Comfortaa/static/Comfortaa-SemiBold.ttf') format('truetype');
    font-weight: 600; /* SemiBold */
}

@font-face {
    font-family: 'Comfortaa';
    src: url('Comfortaa/static/Comfortaa-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
}
